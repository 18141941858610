import { getPerformance, trace } from "firebase/performance";
import { Metric, ReportHandler } from "web-vitals";

/**
 * Send CWV to Firebase
 * @param {Metric}
 * @return {void}
 */
const sendToFirebasePerformance: ReportHandler = ({ name, delta, entries }) => {
    const metricNameMap: Record<Metric['name'], string> = {
        CLS: 'Cumulative Layout Shift',
        LCP: 'Largest Contentful Paint',
        FID: 'First Input Delay',
        TTFB: 'Time To First Byte',
        FCP: 'First Contentful Paint',
    };

    const startTime = Date.now();
    const value = Math.round(name === 'CLS' ? delta * 1000 : delta);

    entries.forEach(({ name: elementName, }) => {
        const attributes: Record<string, string> = elementName ? { element: elementName } : {};


        trace(getPerformance(), metricNameMap[name]).record(
            startTime,
            value,
            {
                attributes,
            }
        );
    });
}

export default sendToFirebasePerformance;
