import React, { FC } from "react";
import styled from "styled-components";

const ButtonST = styled.button`
    background-color: ${({ theme }) => theme.colors.buttonBG};
    height: 72px;
    min-width: 72px;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 11px;
    padding: 0 21px;
    border: none;
    border-radius: 20px;
    cursor: pointer;

    transition: 0.3s;
    user-select: none;

    &:hover {
        transform: scale(1.02);
        background-color: ${({ theme }) => theme.colors.cardSelectedShadow};
    }

    box-shadow: 0px 10px 30px 0px ${({ theme }) => theme.colors.cardSelectedShadow + "60"};
`;

export interface ButtomProps {
    children?: React.ReactNode;
}

const Buttom: FC<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtomProps> = ({ children, ...resProps }) => {
    return (
        <>
            <ButtonST {...resProps}>{children}</ButtonST>
        </>
    );
};

export default Buttom;
