import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'

export const collectionContact = collection(getFirestore(), 'contacts');

interface Contact {
    id: string;
    sss?: boolean;
    Date: string;
    Name: string;
    CPF: string;
    Email: string;
    Phone: string;
    Date_of_birth: string;
    Link: string[];
};

export const createContact = async (contact: Omit<Contact, 'id' | 'Date'>) => {
    const docRef = await doc(collectionContact);
    const saoPauloTime = new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
    await setDoc(docRef, {
        ...contact,
        Date: saoPauloTime,
    } as Contact);
    return docRef.id;
}
