import React from "react";
import Hero from "./Hero";
import OurService from "./UorService";
// import HomeQuotes from "./Quote";
import FAQ from "./FAQ";

const HomePage: React.FC = () => {
    return (
        <>
            <Hero />
            <OurService />
            {/* <HomeQuotes /> */}
            <FAQ />
            <div style={{ height: "60px" , width: '100%'}} />
        </>
    );
};

export default HomePage;
