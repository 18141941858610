import styled from "styled-components";

const BodySession = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin-top: 100px;
    /* padding: 0 100px; */

`;

export const TitleBody = styled.h2`
    width: 100%;
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5em;

    span {
        color: ${({ theme }) => theme.colors.titleName};
    }
`;

export default BodySession;
