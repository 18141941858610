import { FormatFunction } from "input-format";
import { useState } from "react";
import { parsePhoneNumber } from 'libphonenumber-js'

function useUniversalFormaterNumber() {
    const [value, setValue] = useState('');

    const formatterNumber: FormatFunction = (value) => {
        if (!value) {
            return {
                text: '',
                template: '+xx xx xxxxx xxxx'
            };
        }
        try {
            const phoneNumber = parsePhoneNumber(value || '') // 'BR' é o código do país para o Brasil
            if (phoneNumber) {
                const ret = {
                    text: phoneNumber.formatInternational(),
                    template: phoneNumber.formatInternational().replace(/\d/g, 'x'),

                };
                return ret;
            }
        } catch (error) {

        }
        const nun = value.replace(/\D/g, '');

        return {
            text: (nun).length > 1 ? "+" + nun : nun,
            template: '+xx xx xxxxx xxxx'
        };
    }


    const parseNumber = (character: string, value: string) => {
        if (character === ' ') {
            return;
        }

        if (character === 'x') {
            return character;
        }

        if (value.length === 15) {
            return;
        }

        return character;
    }


    return {
        number: value,
        setNumber: setValue,
        formatterNumber,
        parseNumber
    }

}

export default useUniversalFormaterNumber;