import React, { FC, useEffect, useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import BodySession, { TitleBody } from "../../components/BodySession";
import styled from "styled-components";

const Card = styled.div`
    width: clamp(100px, 90%, 12200px);
    background-color: ${({ theme }) => theme.colors.cardButton};
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    padding: 32px;
    margin-bottom: 8px;
    cursor: pointer;
    user-select: none;
    h4 {
        font-weight: 400;
        margin: 0;
        font-size: 18px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }
    p {
        margin: 0;
        text-align: start;
        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${({ theme }) => theme.colors.cardSelected};
    }
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const CardBody = styled.div`
    /* margin-top: 8px; */
    p {
        font-size: 18px;
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }
`;
const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
    margin: 16px 0;
`;

const Acordion: FC<{
    title: string;
    children?: React.ReactNode;
}> = ({ children, ...props }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [maxHeigth, setContentMaxHeight] = useState(0);

    useEffect(() => {
        const calcContentMaxHeight = () => {
            ref.current && setContentMaxHeight(ref.current.scrollHeight);
        };

        calcContentMaxHeight();

        window.addEventListener("resize", calcContentMaxHeight);

        return () => window.removeEventListener("resize", calcContentMaxHeight);
    }, [ref, setContentMaxHeight]);

    const { scY, ...styles } = useSpring({
        scY: open ? -1 : 1,
        opacity: open ? 1 : 0,
        maxHeight: open ? maxHeigth : 0,
        config: {
            duration: 150,
            bounce: 100,
            mass: 100,
        },
    });

    return (
        <Card onClick={() => setOpen(!open)}>
            <CardHeader>
                <h4>{props.title}</h4>
                <div>
                    <animated.svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{
                            transform: scY.to((scy) => `scaleY(${scy})`),
                        }}
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </animated.svg>
                </div>
            </CardHeader>
            <CardBody
                as={animated.div}
                ref={ref}
                style={{
                    overflow: "hidden",
                    ...styles,
                }}
            >
                <Line />
                {children}
            </CardBody>
        </Card>
    );
};

const faqs = [
    {
        question: "Qual o benefício de trabalhar com a SSS?",
        answer: "Inúmeras oportunidades de trabalho, acesso a eventos, benefícios únicos, suporte hibrido e pagamento garantido.",
    },
    {
        question: "Porque escolher a SSS como opção?",
        answer: "A SSS irá garantir que sua parceria irá acontecer como imaginado, assim, nós se responsabilizamos e trabalhamos para que tudo ocorra sem nenhum problema.",
    },
    {
        question: "Como funciona o processo de intermediação?",
        answer: "Após o blogueiro aceitar o serviço, nós asseguramos 100% do valor da parceria conosco e cuidamos de todos detalhes deixando para nossos clientes o único trabalho de receber em mãos seus resultado e pagamentos.",
    },
    {
        question: "Como eu resgato o pagamento da cooperação?",
        answer: "Os pagamentos da SSS são garantidos. Pagamento feito direto na plataforma de parceria.",
    },
];

export interface FAQProps {}

const FAQ: FC<FAQProps> = () => {
    return (
        <BodySession>
            <TitleBody>Algumas de nossas perguntas frequentes</TitleBody>
            {faqs.map((faq, index) => (
                <Acordion key={index} title={faq.question}>
                    <p>{faq.answer}</p>
                </Acordion>
            ))}
        </BodySession>
    );
};

export default FAQ;
