import React, { FC } from "react";
import styled from "styled-components";
import { Title } from "./Contato/styles";

const DivSession = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1120px;
    margin-top: 50px;
    position: relative;
    overflow: hidden;

    background-color: ${({ theme }) => theme.colors.cardAbout};
    border-radius: 28px;
    padding: 80px;
    margin-bottom: 90px;
    p {
        padding: 80px;
        font-size: 22px;

        @media screen and (max-width: 1000px) {
            padding: 20px;
            font-size: 19px;
        }

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 1000px) {
        padding: 30px;
        ${Title} {
            font-size: 50px;
        }
    }

    @media screen and (max-width: 600px) {
        padding: 20px;
        ${Title} {
            font-size: 30px;
        }
    }
`;

// const Circle = styled.img`
//     width: 200px;
//     margin-bottom: 10px;
//     position: absolute;
//     top: 160px;
//     // a imagem se move para a esquerda conforme a tela diminui
//     left: 20px;
//     @media screen and (max-width: 1000px) {
//         top: 40px;
//     }
// `;

// const Cube = styled.img`
//     width: 130px;
//     margin-bottom: 10px;
//     position: absolute;
//     top: 110px;
//     right: 20px;
//     @media screen and (max-width: 1000px) {
//         top: 30px;
//     }

//     @media screen and (max-width: 800px) {
//         top: 55px;
//         width: 110px;
//     }
// `;

export interface SobrePageProps {}

const SobrePage: FC<SobrePageProps> = () => {
    return (
        <>
            <DivSession>
                {/* <Circle src={require("../assets/circle.png")} alt="Grupo SSS" />
                <Cube src={require("../assets/cube.png")} alt="Grupo SSS" /> */}
                <Title>Saiba mais sobre nós</Title>
                <p>
                    Diante do cenário atual de desconfiança no mercado, estamos lançando uma iniciativa para garantir transações seguras entre influenciadores e
                    casas parceiras. Com sede em São Paulo, a SSS está construindo uma plataforma de intermediação para proteger ambas as partes, assegurando
                    o recebimento dos pagamentos combinados. Ao se tornar nosso parceiro, você terá acesso prioritário a oportunidades de divulgação em
                    lançamentos e eventos exclusivos. Além disso, estamos criando uma lista de influenciadores confiáveis para facilitar futuras negociações. Se
                    deseja garantir sua participação e segurança em parcerias promissoras, entre em contato para mais informações e para se tornar um dos
                    primeiros parceiros confiáveis da SSS.
                </p>
            </DivSession>
        </>
    );
};

export default SobrePage;
