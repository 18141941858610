import styled from "styled-components";

export const Session = styled.div`
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap; 
    padding: 32px;

    @media screen and (max-width: 1106px) {
        align-items: center;
        justify-content: center;
    }

    h1 {
        margin: 0;
    }
    p {
        margin: 0;
    }
`;

export const DivCollumn = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
    justify-content: flex-start;

    flex: 1;
    h1 {
        margin: 0;
    }
    p {
        margin: 0;
    }
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 72px;
    padding-bottom: 32px;

    line-height: 84px;
    font-weight: 700;

    @media screen and (max-width: 768px) {
        font-size: 48px;
    }
`;

export const ContratoParagraph = styled.p`
    padding: 20px 0;
    font-size: 20px;
    font-weight: 700;
    user-select: none;
    span {
        color: ${({ theme }) => theme.colors.buttonBG};
        cursor: pointer;
    }
`;

export const TableList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding: 5px 0;
    width: 100%;
`;

export const TableItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex;
    padding: 10px 0;
    width: 100%;
`;

export const TableIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50px;
    height: 50px;
`;

export const Parag = styled.p`
    font-size: 20px;
    font-weight: 400;
    user-select: none;
    width: 399px;

    @media screen and (max-width: 1102px) {
        width: 100%;
    }
`;