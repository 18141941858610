
export const colors = {
    withe: "#FFFFFF",
    main: {
        primary: "#4C40F7",
        secondary: "#FF6800",
    },
    second: {
        red: "#FF3B2F",
        orange: "#FE9602",
        yellow: "#FFCC00",
        green: "#4ADB61",
        blue: "#5CC8FC",
    },
    third: {
        blue: "#007BFF",
        purple: "#5955D8",
        red: "#FF2D59",
    },
    grayScale: {
        black: "#081420",
        dark: "#192431",
        medium: "#212C3A",
        grey: "#70798B",
        light: "#ABAFC7",
        withe: "#F3F4F6",
        blue: '#020E2D',
    }
}

export const ligthTheme = {
    primary: colors.main.primary,
    secondary: colors.main.secondary,
    titleName: colors.second.yellow,
    heroBg: colors.third.red,
    bg: colors.withe,
    text: colors.grayScale.black,
    cardShadow: colors.grayScale.grey,
    cardSelected: colors.second.yellow,
    cardSelectedShadow: colors.second.orange,
    cardButton: colors.grayScale.withe,
    buttonBG: colors.second.yellow,
    quotesBG: colors.second.orange,
    bgFooter: colors.grayScale.blue,
    cardFooter: colors.second.yellow,
    white: colors.grayScale.withe,
    black: colors.grayScale.black,
    cardAbout: colors.grayScale.withe,
};

export type ThemeColors = typeof ligthTheme;

export const darkTheme: ThemeColors = {
    primary: colors.second.red,
    secondary: colors.third.red,
    titleName: colors.second.red,
    heroBg: colors.third.blue,
    bg: colors.grayScale.black,
    text: colors.grayScale.withe,
    cardShadow: colors.second.red,
    cardSelected: colors.second.red,
    cardSelectedShadow: colors.third.red,
    cardButton: colors.grayScale.blue,
    quotesBG: colors.grayScale.blue,
    bgFooter: colors.grayScale.blue,
    cardFooter: colors.main.secondary,
    buttonBG: colors.second.red,
    white: colors.grayScale.withe,
    black: colors.grayScale.black,
    cardAbout: colors.grayScale.black,

};
