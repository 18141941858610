import React, { FC } from "react";
import styled from "styled-components";

import LogoX1 from "../../assets/BRANCO.svg";
// const LogoX2 = require("../../assets/Logo@2x.png");

const LogoPicture = styled.picture<{ height: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ height }) => height}px;
    box-sizing: border-box;
    cursor: pointer;
    * {
        height: 100%;
    }
`;
export interface LogoProps {
    height?: number;
}

const Logo: FC<LogoProps> = ({ height = 150 }) => {
    return (
        <LogoPicture height={height}>
            {/* <source srcSet={LogoX2} /> */}
            <img src={LogoX1} alt="Logo" />
        </LogoPicture>
    );
};

export default Logo;
