import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const ModalSession = styled.div`
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    left: 0;
    top: 0;

    z-index: 10;
    position: fixed;
`;

const ModalBody = styled.div`
    width: clamp(320px, 65%, 2000px);
    height: clamp(100px, 90%, 2000px);
    position: relative;
    gap: 5px;
    border-radius: 28px;

    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    user-select: none;
    h6 {
        font-size: 20px;
        font-weight: 600;
        line-height: 10px;
    }
    background-color: ${({ theme }) => theme.colors.cardAbout};
`;

export interface ModalProps {
    open?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}

const Modal: FC<ModalProps> = ({ onClose, open, children }) => {
    const [isOpen, setIsOpen] = useState(!!open);
    const ref = useRef<HTMLDivElement>(null);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        onClose?.();
    }, [onClose]);

    useEffect(() => {
        if (open) {
            setIsOpen(true);
        }
    }, [open]);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                handleClose();
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [handleClose]);

    console.log(isOpen);

    if (!isOpen) {
        return null;
    }

    return (
        <ModalSession>
            <ModalBody ref={ref}>{children}</ModalBody>
        </ModalSession>
    );
};

export default Modal;
