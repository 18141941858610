import React, { FC } from "react";
import styled from "styled-components";

import { ReactComponent as Shape0 } from "../../assets/Shape_0.svg";

const BG = styled.div`
    color: ${({ theme }) => theme.colors.text};
    width: 100vw;
    min-height: 100vh;
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
    box-sizing: border-box;
    transition: background-color 0.5s, color 0.5s;
    * {
        color: ${({ theme }) => theme.text};
    }

    #chil {
        text-align: center;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    position: absolute;
`;

const ShapeDiv = styled.div`
    background-color: ${({ theme }) => theme.colors.bg};
    position: absolute;
    overflow: hidden;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: -1;
`;

export interface BackGroundProps {
    children?: React.ReactNode;
}

const BackGround: FC<BackGroundProps> = ({ children }) => {
    return (
        <BG>
            <div id="chil">{children}</div>
            <ShapeDiv>
                <div
                    style={{
                        position: "relative",
                        top: 100,
                        left: 0,
                        minHeight: "100vh",
                        width: "100vw",
                        zIndex: 0,
                    }}
                >
                    {Array.from({ length: 30 }).map((_, i) => (
                        <Shape0 key={`shape-${i}`} />
                    ))}
                </div>
            </ShapeDiv>
        </BG>
    );
};

export default BackGround;
