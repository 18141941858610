import React, { FC } from "react";
import BackGround from "../components/BG";
import NavBar  from "../components/NavBar";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import { useTheme } from "../contexts/theme";

export interface DefaultRouterProps {}


const DefaultRouter: FC<DefaultRouterProps> = () => {
    const { colors } = useTheme();
    return (
        <BackGround>
            <Helmet>
                <meta name="theme-color" content={colors.bg} />
            </Helmet>
            <NavBar />
            <Outlet />
            <Footer />
        </BackGround>
    );
};

export default DefaultRouter;
