import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./middleware/firebase";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./contexts/theme";
import { RouterProvider } from "react-router-dom";
import router from "./pages";
import sendToFirebasePerformance from "./middleware/webVitals";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    *::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: ${({ theme }) => theme.colors.bg};
        overflow: hidden;
    }

    *::-webkit-scrollbar{
        border-radius: 10px;
        width: 12px;
        background-color: ${({ theme }) => theme.colors.bg};
        overflow: hidden;
    }

    *::-webkit-scrollbar-thumb{ 
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: ${({ theme }) => theme.colors.buttonBG};
        overflow: hidden;
    }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ThemeProvider>
            <GlobalStyle />
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToFirebasePerformance);
