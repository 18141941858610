import React, { FC, useRef } from "react";
import ReactInput from "input-format/react";

import styled, { css } from "styled-components";

const InputBody = styled.div`
    width: 100%;
    height: calc(72px - 6px);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    background-color: ${({ theme }) => theme.colors.bg};
    border: ${({ theme }) => "3px solid " + theme.colors.buttonBG};
    border-radius: 11px;
    margin-bottom: 21px;
    /* padding: 0 11px; */
    user-select: none;
    transition: background-color 0.3s ease;
    position: relative;
    &:hover {
        transform: scale(1.02);
        color: ${({ theme }) => theme.colors.bg};
    }
`;

const InputStyled = css`
    height: 100%;
    font-size: 18px;
    font-weight: 700;
    padding: 0 11px;
    width: calc(100% - 22px);
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.colors.text};

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: ${({ theme }) => theme.colors.text};
        transition: background-color 500000s ease-in-out 0s;
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.text + "50"};
    }
`;
/*
    input[type="date"]:not(.has-value):before{
    color: lightgray;
    content: attr(placeholder);
    }
 */
const InputST = styled.input`
    ${InputStyled}

    ${({ type }) =>
        type === "date" &&
        css`
            &::-webkit-calendar-picker-indicator {
                filter: opacity(0);
            }
        `}

    ${({ type, value }) =>
        type === "date" &&
        value === "" &&
        css`
            width: 0px;
            &::before {
                content: attr(placeholder);
                position: absolute;
                color: ${({ theme }) => theme.colors.text + "50"};
                width: 100%;
            }
        `}
`;

const InputSTFormater = styled(ReactInput)`
    ${InputStyled}
`;

export enum InputTypes {
    FORMATER = "formater",
    NORMAL = "normal",
}

type InputPropsFormater = React.ComponentProps<typeof ReactInput> & {
    inputType: InputTypes.FORMATER;
};

type InputPropsNormal = React.InputHTMLAttributes<HTMLInputElement> & {
    inputType: InputTypes.NORMAL;
};

export type InputProps = (InputPropsFormater | InputPropsNormal) & {
    isDate?: boolean;
};

// extends React.InputHTMLAttributes<HTMLInputElement>
const Input: FC<InputProps> = ({ isDate, ...props }) => {
    const refInp = useRef<HTMLInputElement>(null);

    if (props.inputType === InputTypes.FORMATER) {
        // Neste ponto, TypeScript sabe que 'props' é do tipo 'InputPropsFormater'
        const { inputType, ...otherProps } = props;
        return (
            <InputBody>
                <InputSTFormater {...otherProps} />
            </InputBody>
        );
    } else {
        // Neste ponto, TypeScript sabe que 'props' é do tipo 'InputPropsNormal'
        const { inputType, ...otherProps } = props;

        return (
            <InputBody
                onClick={() => {
                    if (refInp.current && refInp.current.type === "date") {
                        refInp.current.click();
                        refInp.current.showPicker();
                    }
                }}
            >
                <InputST ref={refInp} {...otherProps} />
            </InputBody>
        );
    }
};

export default Input;
