import styled from "styled-components";

export const NavBarDIV = styled.div`
    color: ${({ theme }) => theme.colors.text};
    height: 120px;
    width: clamp(100px, 90%, 2000px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    transition: background-color 0.5s, color 0.5s;
`;

export const NavBarTitle = styled.div<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
    cursor: pointer;
    user-select: none;
    h6 {
        font-size: 20px;
        font-weight: 600;
        line-height: 10px;
        color: ${({ selected, theme }) => (selected ? theme.colors.cardSelected : theme.colors.text)};
    }
`;

export const NavBarMobleSession = styled.div<{ open?: boolean }>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 90px;

    z-index: 1;

    @media screen and (max-width: 920px) {
        display: ${({ open }) => (open ? "flex" : "none")};
        flex-direction: column;
        gap: 20px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.cardButton};
        top: 100px;
        right: 20px;
        width: 200px;
    }
`;

export const DivLinks = styled.div`
    flex: 1;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 90px;
    @media screen and (max-width: 920px) {
        flex-direction: column;
        gap: 4px;
    }
`;

export const Menu = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    gap: 10px;
    @media screen and (max-width: 920px) {
        display: flex;
    }
`;

export interface NavBarProps {}
