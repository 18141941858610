import styled from "styled-components";
import BodySession from "../../components/BodySession";

const HeroImage = require("../../assets/HeroImage.png");
const HeroImageX2 = require("../../assets/HeroImage@2x.png");
const HeroImageX3 = require("../../assets/HeroImage@3x.png");
const HeroImageX4 = require("../../assets/HeroImage@4x.png");

const TitleName = styled.h1`
    font-size: 72px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5em;

    span {
        color: ${({ theme }) => theme.colors.titleName};
    }
`;

const Session = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    text-align: start;

    @media screen and (max-width: 1160px) {
        text-align: center;
        flex-direction: column-reverse;
    }
`;

const SessionTexts = styled.div`
    flex: 1;
    min-width: 340px;
    max-width: 700px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1160px) {
        align-items: center;
    }
`;

const ImageDiv = styled.div`
    width: 570px;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        @media screen and (max-width: 1160px) {
            width: 80%;
        }
        @media screen and (max-width: 800px) {
            width: 90%;
        }
        max-width: 140%;
    }

    @media screen and (max-width: 1160px) {
        width: 100%;
    }
`;

const Hero = () => {
    return (
        <BodySession>
            <Session>
                <SessionTexts>
                    <TitleName>
                        GRUPO <span>SSS</span>
                    </TitleName>
                    <p style={{ maxWidth: "460px", fontSize: "26px", flex: 1 }}>
                        Somos uma empresa que atua como intermediária, promotora e cuidadora da SUA parceria, com o objetivo de aprimorar o mercado atual. A
                        SSS surge como solução para influenciadores e casas parceiras.
                    </p>
                </SessionTexts>
                <ImageDiv>
                    <picture>
                        <source srcSet={HeroImageX4} media="(min-width: 1440px)" />
                        <source srcSet={HeroImageX3} media="(min-width: 1024px)" />
                        <source srcSet={HeroImageX2} media="(min-width: 768px)" />
                        <img src={HeroImage} alt="Grupo SSS" />
                    </picture>
                    {/* <img src={HeroImage} alt="Grupo SSS" /> */}
                </ImageDiv>
            </Session>
        </BodySession>
    );
};

export default Hero;
