// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCy_q-34IcSKwn9yBZk5khfoz26gw3IY8A",
    authDomain: "yallo-c8da9.firebaseapp.com",
    projectId: "yallo-c8da9",
    storageBucket: "yallo-c8da9.appspot.com",
    messagingSenderId: "1015223719750",
    appId: "1:1015223719750:web:d4e73f559f17d11ecdf747",
    measurementId: "G-M447C0WZPR"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const performance = getPerformance(app);