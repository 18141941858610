import React, { FC, useState } from "react";
import createCTX from "../../utils/createCTX";
import { useMediaQuery } from "@uidotdev/usehooks";
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from "styled-components";
import { ligthTheme, darkTheme, colors } from "../../theme/colors";

// const DEV_MODE = process.env.NODE_ENV !== "production";

enum ThemeChoice {
    LIGTH = "ligth",
    DARK = "dark",
}

const [useContext, ContexProvider] = createCTX<DefaultTheme>();

const ThemeProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [themeChoice] = useState<ThemeChoice>(ThemeChoice.DARK);

    // const isDark = useMediaQuery("(prefers-color-scheme: dark)");
    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const isTablet = useMediaQuery("(min-width: 768px)");
    const isMobile = useMediaQuery("(max-width: 767px)");

    // useEffect(() => {
    //     if (DEV_MODE) {
    //         const rere = setInterval(() => {
    //             setThemeChoice((prev) => (prev === ThemeChoice.LIGTH ? ThemeChoice.DARK : ThemeChoice.LIGTH));
    //         }, 2000);
    //         return () => clearInterval(rere);
    //     } else {
    //         setThemeChoice(isDark ? ThemeChoice.DARK : ThemeChoice.LIGTH);
    //     }
    // }, [isDark]);

    const value: DefaultTheme = {
        pallets: colors,
        colors: themeChoice === ThemeChoice.LIGTH ? ligthTheme : darkTheme,
        isDark: themeChoice !== ThemeChoice.LIGTH,
        isDesktop,
        isTablet,
        isMobile,
    };

    return (
        <StyledThemeProvider theme={value}>
            <ContexProvider value={value}>{children}</ContexProvider>
        </StyledThemeProvider>
    );
};

export { useContext as useTheme, ThemeProvider };
