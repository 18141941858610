import React, { FC } from "react";
import styled from "styled-components";
import Input, { InputTypes } from "../../components/Input";
import useUniversalFormaterNumber from "../../middleware/universalFormaterNumber";
import { parseDigit, templateFormatter, templateParser } from "input-format";
import SocialLinks from "./SocialLinks";
import { ContratoParagraph } from "./styles";
import Buttom from "../../components/Button";
import * as yup from "yup";
import { Formik } from "formik";
import { createContact } from "../../model/cotacts";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import ContratoModal from "./contrato";

const Label = styled.label`
    margin: 0;
    color: #808080;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 8px;
`;

const LabelError = styled.label`
    position: relative;
    height: 0px;
    top: -20px;
    margin: 0;
    color: #ff0000;
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 8px;
`;

const formSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    date: yup.date().required("Data de nascimento é obrigatório"),
    socialLinks: yup
        .string()
        .required("link sociais sao obrigatorios")
        .test("is-socialLinks", "Link inválido", (value) => {
            if (!value) return false;
            const links = value.split(";");
            let valid = true;
            links.forEach((link) => {
                console.log(link);
                if (link.startsWith("/") && link?.length > 5) return;
                try {
                    new URL(link);
                    return;
                } catch (e) { }
                valid = false;
            });
            return valid;
        }),
    email: yup.string().email("Email inválido").required("Email é obrigatório"),
    number: yup.string().required("Número é obrigatório"),
    cpf: yup
        .string()
        .required("CPF é obrigatório")
        .test("is-cpf", "CPF inválido", (value) => {
            if (!value) return false;
            const cpf = value.replace(/\D/g, "");
            if (cpf.length !== 11) return false;
            if (cpf === "00000000000") return false;
            let sum = 0;
            let rest;
            for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            if (rest !== parseInt(cpf.substring(9, 10))) return false;
            sum = 0;
            for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
            rest = (sum * 10) % 11;
            if (rest === 10 || rest === 11) rest = 0;
            if (rest !== parseInt(cpf.substring(10, 11))) return false;
            return true;
        }),
});

export interface FormsProps { }

const Forms: FC<FormsProps> = () => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { setNumber, formatterNumber, parseNumber } = useUniversalFormaterNumber();

    const navigate = useNavigate();
    const get18YearsAgo = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        return date.toISOString().split("T")[0];
    };

    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                    date: "",
                    socialLinks: "",
                    cpf: "",
                    email: "",
                    number: "",
                }}
                validationSchema={formSchema}
                onSubmit={async (values) => {
                    try {
                        setLoading(true);
                        await createContact({
                            CPF: values.cpf,
                            Date_of_birth: values.date,
                            Email: values.email,
                            sss: true,
                            Name: values.name,
                            Phone: values.number,
                            Link: values.socialLinks.split(";"),
                        });

                        navigate("/?success=true");
                        setLoading(false);
                    } catch (e) {
                        console.error(e);
                        setLoading(false);
                    }
                }}
            >
                {({ errors, touched, dirty, handleChange, values: { name, date, socialLinks, cpf, email, number }, handleBlur, handleSubmit }) => {
                    return (
                        <>
                            <Input
                                inputType={InputTypes.NORMAL}
                                placeholder="Nome"
                                type="text"
                                maxLength={50}
                                alt="Nome"
                                onChange={(e) => {
                                    if (e?.target) {
                                        handleChange("name")(e.target.value);
                                    }
                                }}
                                onBlur={handleBlur("name")}
                                value={name}
                            />
                            {errors.name && <LabelError>{errors.name}</LabelError>}
                            <Input
                                inputType={InputTypes.FORMATER}
                                placeholder="CPF"
                                type="text"
                                alt="CPF"
                                format={templateFormatter("xxx.xxx.xxx-xx")}
                                parse={templateParser("xxx.xxx.xxx-xx", parseDigit)}
                                onChange={(e) => {
                                    if (typeof e === "string") {
                                        handleChange("cpf")(e);
                                    } else if (e?.target) {
                                        handleChange("cpf")(e.target.value);
                                    }
                                }}
                                onBlur={handleBlur("cpf")}
                                value={cpf}
                            />
                            {errors.cpf && <LabelError>{errors.cpf}</LabelError>}
                            <Input
                                inputType={InputTypes.NORMAL}
                                placeholder="Email"
                                type="email"
                                maxLength={50}
                                alt="Email"
                                onChange={(e) => {
                                    if (e?.target) {
                                        handleChange("email")(e.target.value);
                                    }
                                }}
                                onBlur={handleBlur("email")}
                                value={email}
                            />
                            {errors.email && <LabelError>{errors.email}</LabelError>}
                            <Input
                                inputType={InputTypes.FORMATER}
                                placeholder="Telefone"
                                type="tel"
                                alt="Telefone"
                                format={formatterNumber}
                                parse={parseNumber}
                                onChange={(e) => {
                                    if (typeof e === "string") {
                                        handleChange("number")(e);
                                        setNumber(e);
                                    } else if (e?.target) {
                                        handleChange("number")(e.target.value);
                                        setNumber(e.target.value);
                                    }
                                }}
                                onBlur={handleBlur("number")}
                                value={number}
                            />
                            {errors.number && <LabelError>{errors.number}</LabelError>}
                            <Input
                                isDate
                                inputType={InputTypes.NORMAL}
                                placeholder="Data de Nascimento"
                                type="date"
                                alt="Data de Nascimento"
                                value={date}
                                onChange={(e) => {
                                    if (e?.target) {
                                        handleChange("date")(e.target.value);
                                    }
                                }}
                                onBlur={handleBlur("date")}
                                max={get18YearsAgo()}
                            />
                            {errors.date && <LabelError>{errors.date}</LabelError>}
                            <Label>Link Redes sociais (mínimo 1)</Label>
                            <SocialLinks links={socialLinks?.split(";")} onChange={(e) => handleChange("socialLinks")(e.join(";"))} />
                            {errors.socialLinks && <LabelError>{errors.socialLinks}</LabelError>}
                            <ContratoParagraph>
                                Ao continuar você esta estará concordando com nosso contrato. <span onClick={() => setOpen(true)}>click aqui</span>, para saber
                                mais.
                            </ContratoParagraph>
                            <Buttom
                                style={{ width: "100%" }}
                                onClick={() => {
                                    if (!loading) handleSubmit();
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <p style={{ fontSize: "18px", fontWeight: 600, color: "#fff" }}>Quero receber propostas</p>
                                    {loading && <Loader />}
                                </div>
                            </Buttom>
                        </>
                    );
                }}
            </Formik>

            <ContratoModal open={open} onClose={() => setOpen(false)} />
        </>
    );
};

export default Forms;
