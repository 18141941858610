import React, { FC } from "react";
import styled from "styled-components";

import { ReactComponent as TwitterIcon } from "../../assets/Twitter.svg";
// import { ReactComponent as WhatsapIcon } from "../../assets/Mobile.svg";

const Session = styled.div`
    /* max-width: 400px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;

    @media screen and (max-width: 1106px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;

const DivColumn = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    text-align: start;
    align-items: center;
    justify-content: center;
    flex: 1;

    @media screen and (max-width: 860px) {
        flex-direction: column;
    }
`;

const Card = styled.div`
    height: 100px;
    min-width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: ${({ theme }) => theme.colors.cardButton};
    border-radius: 20px;

    box-shadow: -20px 20px 60px 0px ${({ theme }) => theme.pallets.main.primary + "80"};
    padding: 20px 0;
    cursor: pointer;

    &:hover {
        transform: scale(1.02);
    }

    h4 {
        flex: 1;
        margin: 0;
        font-size: 20px;
        font-weight: 400;
        user-select: none;
    }
`;

const CardIcon = styled.div`
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.colors.buttonBG};
    transition: 0.3s;
    margin: 0 20px;

    ${Card}:hover & {
        background-color: ${({ theme }) => theme.colors.buttonBG};
        svg {
            * {
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }

    svg {
        * {
            fill: ${({ theme }) => theme.colors.buttonBG};
        }
        height: 25px;
        width: 25px;
    }
`;

export interface SociaisMedeasProps {}

const SociaisMedeas: FC<SociaisMedeasProps> = () => {
    return (
        <Session>
            <DivColumn>
                <Card
                    style={{ zIndex: 4 }}
                    onClick={() => {
                        window.open("https://www.instagram.com/sssgrupo1/", "_blank");
                    }}
                >
                    <CardIcon>
                        <TwitterIcon />
                    </CardIcon>
                    <h4>Instagram</h4>
                </Card>
                {/* <Card
                    style={{ zIndex: 3 }}
                    onClick={() => {
                        window.open("https://wa.me/5511954874958", "_blank");
                    }}
                >
                    <CardIcon>
                        <WhatsapIcon />
                    </CardIcon>
                    <h4>Whatsapp</h4>
                </Card> */}
            </DivColumn>
        </Session>
    );
};

export default SociaisMedeas;
