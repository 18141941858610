import React, { FC } from "react";
import "./index.css";

export interface LoaderProps {}

const Loader: FC<LoaderProps> = () => {
    return <div className="loader">Loading...</div>;
};

export default Loader;
